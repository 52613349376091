import React from 'react';
import { availableDiets as dietTypes } from 'config/config';
import withLoading from 'Layout/withLoading';
import PropTypes from 'prop-types';
import deepClone from 'utils/deepClone';
import { scrollToTop } from 'components/Helpers/scrollToTop';
import { Redirect } from 'react-router';
import { PATH_ACTIVITIES_INDEX } from 'Activities/paths';
import { formatIsoDate, addDays } from 'utils/dateFormatter';
import { validateFields } from 'view/Validation/validateFields';
import DateFormatRule from 'view/Validation/ValidationRules/DateFormatRule';
import RequiredRule from 'view/Validation/ValidationRules/RequiredRule';
import dietSettingsSteps from 'config/dietSettingsSteps';
import { PATH_DAY_PLAN_INDEX } from '../../DailyDietPlan/DayPlanPaths';
import DietSettingsPlaceholder from './DietSettingsPlaceholder';
import DietSettings from './DietSettings';
import withDebounce from '../withDebounce';
import StepProvider from '../Steps/StepContext/StepProvider';
import StepActions from '../Steps/StepActions';

import StepBmr from '../Steps/StepBmr';
import StepDietId from '../Steps/StepDietId';
import StepGoal from '../Steps/StepGoal';
import StepStartDate from '../Steps/StepStartDate';
import StepTest from '../Steps/StepTest';

import { dietSettingsGetNextStep } from '../utils/NextStep/DietSettingsNextStepAction';
import { dietSettingsGetPreviousStep } from '../utils/PreviousStep/DietSettingsPreviousStepAction';
import { dietSettingsGetProgress } from '../utils/Progress/DietSettingsProgressAction';
import StepDietLevel from '../Steps/StepDietLevel';
import StepTriangle from '../Steps/StepTriangle';
import StepActivitySimple from '../Steps/StepActivitySimple';
import StepActivityType from '../Steps/StepActivityType';

import { withLocale } from '../../../TranslatorContext';

class DietSettingsIndexContainer extends React.Component {
    weightDefaultUnit =
        this.props.dietSettings.systemOfMeasures === 'imperial' ? 'lb' : 'kg';

    heightDefaultUnit =
        this.props.dietSettings.systemOfMeasures === 'imperial' ? 'in' : 'cm';

    heightDefaultValue =
        this.props.dietSettings.systemOfMeasures === 'imperial' ? 60 : 160;

    state = {
        userName: this.props.dietSettings.name,
        systemOfMeasures: this.props.dietSettings.systemOfMeasures,
        currentStep: 'bmr',
        progress: 0,
        sex: this.props.dietSettings.sex || undefined,
        dateOfBirth: this.props.dietSettings.birthDate || '1980-01-01',
        height: {
            value: this.props.dietSettings.height
                ? this.props.dietSettings.height.value
                : this.heightDefaultValue,
            unit: this.props.dietSettings.height
                ? this.props.dietSettings.height.unit
                : this.heightDefaultUnit,
        },
        weight: {
            value: this.props.dietSettings.lastMeasurement.weight[0]
                ? this.props.dietSettings.lastMeasurement.weight[0].value
                : undefined,
            unit: this.props.dietSettings.lastMeasurement.weight[0]
                ? this.props.dietSettings.lastMeasurement.weight[0].unit
                : this.weightDefaultUnit,
        },
        dietMode: this.props.dietSettings.diet.mode || undefined,
        goalWeight: {
            value:
                (this.props.dietSettings.goalWeight || {}).value || undefined,
            unit:
                (this.props.dietSettings.goalWeight || {}).unit ||
                this.weightDefaultUnit,
        },
        activityLevel: this.props.dietSettings.diet.activityLevel || undefined,
        activityType: this.props.dietSettings.diet.activityType || undefined,
        dietLevel:
            this.props.dietSettings.diet.id === 10 ||
            this.props.dietSettings.diet.id === 11 ||
            this.props.dietSettings.diet.id === 12
                ? 1
                : this.props.dietSettings.diet.id,
        dietId: this.props.dietSettings.diet.id || undefined,
        dietType: this.props.dietSettings.diet.dietType || undefined,
        startDate: formatIsoDate(addDays(new Date(), 1)),
        triangleOfPower: this.props.dietSettings.diet.triangleOfPower,
        isNextButtonDisabled: false,
        errors: undefined,
        redirect: false,
        redirectTrainings: false,
        hadEver: this.props.dietSettings.diet.hadEver,
        generateDietError: false,
        psychotest: this.props.psychotest,
        testStep: 1,
        preload: false,
        preloadNextStep: false,
    };

    validationRules = {
        dateOfBirth: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
        startDate: [
            new RequiredRule({ translator: this.props.t }),
            new DateFormatRule({ translator: this.props.t }),
        ],
        userName: [new RequiredRule({ translator: this.props.t })],
        weight: [new RequiredRule({ translator: this.props.t })],
        height: [new RequiredRule({ translator: this.props.t })],
        goalWeight: [new RequiredRule({ translator: this.props.t })],
        sex: [new RequiredRule({ translator: this.props.t })],
    };

    static propTypes = {
        dietSettings: PropTypes.shape().isRequired,
        psychotest: PropTypes.arrayOf(PropTypes.number.isRequired),
        validateData: PropTypes.func.isRequired,
        generateDiet: PropTypes.func.isRequired,
        debounce: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
    };

    static defaultProps = {
        psychotest: [0, 1, 4, 7, 10, 13, 16, 19, 22],
    };

    componentDidMount() {
        this.updateProgressBar(this.state.currentStep);
    }

    handleInputChange = event => {
        const fieldName = event.target.name.split('.')[0];
        const targetValue = event.target.value;
        const valInt = parseInt(targetValue, 10) || 0;
        const valFloat = Math.round(parseFloat(targetValue) * 10) / 10 || 0;
        if (fieldName === 'weight' || fieldName === 'goalWeight') {
            this.setState(prevState => ({
                [fieldName]: {
                    ...prevState[fieldName],
                    value: valFloat,
                },
            }));
        } else if (fieldName === 'height') {
            this.setState(prevState => ({
                [fieldName]: {
                    ...prevState[fieldName],
                    value: valInt,
                },
            }));
        } else if (fieldName.includes('psychotest')) {
            // eslint-disable-next-line react/no-access-state-in-setstate
            const newPsychotest = this.state.psychotest;
            newPsychotest[this.state.testStep] = parseInt(targetValue, 10);
            this.setState({ psychotest: newPsychotest });
        } else if (fieldName === 'dietId' || fieldName === 'dietLevel') {
            this.setState({
                [fieldName]: valInt,
            });
        } else if (
            fieldName === 'mind' ||
            fieldName === 'body' ||
            fieldName === 'libido'
        ) {
            this.setState(prevState => ({
                triangleOfPower: {
                    ...prevState.triangleOfPower,
                    [fieldName]: !prevState.triangleOfPower[fieldName],
                },
            }));
        } else if (
            (fieldName === 'dateOfBirth' || fieldName === 'startDate') &&
            targetValue === ''
        ) {
            this.setState({
                [fieldName]: undefined,
            });
        } else {
            this.setState({ [fieldName]: targetValue });
        }
        const validableFields = [
            'dateOfBirth',
            'weight',
            'height',
            'goalWeight',
            'startDate',
            'userName',
        ];
        validableFields.forEach(field => {
            if (field === fieldName) {
                this.props.debounce(this.validateData, 1000);
            }
        });
    };

    createRequest() {
        const dietLevel = this.state.dietLevel
            ? this.state.dietLevel
            : this.state.dietId;

        const request = {
            sex: this.state.sex,
            userName: this.state.userName,
            weight: this.state.weight.value ? this.state.weight : undefined,
            height: this.state.height,
            dateOfBirth: this.state.dateOfBirth,
            dietMode: this.state.dietMode,
            goalWeight: this.state.goalWeight.value
                ? this.state.goalWeight
                : undefined,
            triangleOfPower: {
                body: this.state.triangleOfPower.body,
                mind: this.state.triangleOfPower.mind,
                libido: this.state.triangleOfPower.libido,
            },
            dietId:
                this.state.dietId === 1 ||
                this.state.dietId === 2 ||
                this.state.dietId === 3
                    ? dietLevel
                    : this.state.dietId,
            activityType:
                this.state.dietId === dietTypes.DIET_ID_VEGE ||
                this.state.dietId === dietTypes.DIET_ID_SMART ||
                this.state.dietId === dietTypes.DIET_ID_LOW_LG
                    ? null
                    : this.state.activityType,
            activityLevel: this.state.activityLevel,
            startDate: this.state.startDate,
        };

        if (request.dietMode === 'stabilization') {
            request.goalWeight = deepClone(request.weight);
        }
        return request;
    }

    validateData = async () => {
        const frontEndErrors = validateFields(
            this.validationRules,
            this.state,
            this.props.t
        );

        if (frontEndErrors.length === 0) {
            const request = this.createRequest();
            const response = await this.props.validateData(request);
            this.setState({});

            if (response.data.me.dietGenerate.code !== 200) {
                this.setState({
                    errors: response.data.me.dietGenerate,
                });
                return response.data.me.dietGenerate;
            }
        }

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                details: frontEndErrors,
            },
        }));

        return this.state.errors;
    };

    generateDiet = async () => {
        // reset generate diet error
        this.setState({ generateDietError: false });
        const request = this.createRequest();
        this.setState({ preload: true });

        try {
            const response = await this.props.generateDiet(request);
            const { code } = response.data.me.dietGenerate;
            this.setState({ preload: false });
            if (code === 200) {
                // set sex for trsanslator
                global.localStorage.setItem('sex', this.state.sex);
                this.setState({ redirect: true });
            } else {
                this.setState({ generateDietError: true });
                throw new Error(
                    `Failed to generate diet, got status code ${code}`
                );
            }
        } catch (e) {
            this.setState({ generateDietError: true });
            throw new Error(`Failed to generate diet, got error: ${e}`);
        }
    };

    animate = (action, duration) => {
        setTimeout(() => {
            this.setState({
                preloadNextStep: false,
            });
        }, duration);

        const wrapper = document.getElementById('wrapper');
        wrapper.classList.add('animated', 'animated-fast', 'fadeOut');

        setTimeout(
            () => wrapper.classList.remove(...wrapper.classList),
            duration
        );
        setTimeout(() => wrapper.classList.add('hide'), duration);
        setTimeout(() => action(), duration);
        setTimeout(() => wrapper.classList.add('animated', 'fadeIn'), duration);
    };

    prevStep = () => {
        if (this.state.currentStep === 'test') {
            if (this.state.testStep === 1) {
                const state = {
                    ...this.props.dietSettings,
                    dietId: this.state.dietId,
                    activityType: this.state.activityType,
                };

                const prevStep = dietSettingsGetPreviousStep(
                    dietSettingsSteps,
                    state,
                    this.state.currentStep
                );

                this.animate(() => this.changeStep(prevStep), 200);
            } else {
                this.animate(
                    // eslint-disable-next-line react/no-access-state-in-setstate
                    () => this.setState({ testStep: this.state.testStep - 1 })
                );
            }
        } else {
            const state = {
                ...this.props.dietSettings,
                dietId: this.state.dietId,
                activityType: this.state.activityType,
            };

            const prevStep = dietSettingsGetPreviousStep(
                dietSettingsSteps,
                state,
                this.state.currentStep
            );
            this.animate(() => this.changeStep(prevStep), 200);
        }
    };

    nextStep = async () => {
        let errors = false;
        if (this.state.currentStep === 'bmr') {
            this.setState({ preloadNextStep: true });
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (
                    error.fieldName === 'weight' ||
                    error.fieldName === 'dateOfBirth' ||
                    error.fieldName === 'height' ||
                    error.fieldName === 'userName'
                ) {
                    errors = true;
                }
            });
        } else if (this.state.currentStep === 'goal') {
            this.setState({ preloadNextStep: true });
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (error.fieldName === 'goalWeight') {
                    errors = true;
                }
            });
        } else if (this.state.currentStep === 'start-date') {
            this.setState({ preloadNextStep: true });
            const responseErrors = await this.validateData();
            responseErrors.details.forEach(error => {
                if (error.fieldName === 'startDate') {
                    errors = true;
                }
            });
        }

        if (!errors) {
            if (this.state.currentStep === 'test') {
                if (this.state.testStep === 7) {
                    this.setState({ errors: undefined });

                    const state = {
                        ...this.props.dietSettings,
                        dietId: this.state.dietId,
                        activityType: this.state.activityType,
                    };

                    const nextStep = dietSettingsGetNextStep(
                        dietSettingsSteps,
                        state,
                        this.state.currentStep
                    );

                    this.animate(() => this.changeStep(nextStep), 200);
                } else {
                    this.animate(
                        () =>
                            this.setState({
                                // eslint-disable-next-line react/no-access-state-in-setstate
                                testStep: this.state.testStep + 1,
                            }),
                        200
                    );
                }
            } else {
                this.setState({ errors: undefined });

                const state = {
                    ...this.props.dietSettings,
                    dietId: this.state.dietId,
                    activityType: this.state.activityType,
                };

                const nextStep = dietSettingsGetNextStep(
                    dietSettingsSteps,
                    state,
                    // eslint-disable-next-line react/no-access-state-in-setstate
                    this.state.currentStep
                );

                if (nextStep === 'activity-trainings') {
                    this.setState({ redirectTrainings: true });
                } else if (!nextStep) {
                    this.generateDiet();
                } else {
                    this.animate(() => this.changeStep(nextStep), 200);
                }
            }
        } else {
            this.setState({ preloadNextStep: false });
        }
    };

    setDefaultActivityLevel = () => {
        this.setState({ activityLevel: 'ACTIVITY_LEVEL_NONE' });
    };

    changeStep = nextStep => {
        scrollToTop();
        this.setState({
            currentStep: nextStep,
        });
        this.updateProgressBar(nextStep);
    };

    updateProgressBar = nextStep => {
        const state = {
            ...this.props.dietSettings,
            dietId: this.state.dietId,
            activityType: this.state.activityType,
        };

        const progress = dietSettingsGetProgress(
            dietSettingsSteps,
            state,
            nextStep
        );

        if (this.state.progress !== progress) {
            this.setState({ progress });
        }
    };

    changeNextBtnState = isDisable => {
        if (this.state.isNextButtonDisabled !== isDisable) {
            this.setState({ isNextButtonDisabled: isDisable });
        }
    };

    renderStep = currentStep => {
        switch (currentStep) {
            case 'bmr':
                return (
                    <StepBmr
                        name={this.props.dietSettings.name}
                        sex={this.state.sex}
                        userName={this.state.userName}
                        systemOfMeasures={this.state.systemOfMeasures}
                        height={this.state.height}
                        weight={this.state.weight}
                        dateOfBirth={this.state.dateOfBirth}
                        errors={this.state.errors}
                        validateData={this.validateData}
                        validationRules={this.validationRules}
                    />
                );
            case 'diet-id':
                return <StepDietId dietId={this.state.dietId} />;
            case 'goal':
                return (
                    <StepGoal
                        dietMode={this.state.dietMode}
                        goalWeight={this.state.goalWeight}
                        weight={this.state.weight}
                        errors={this.state.errors}
                        validateData={this.validateData}
                        validationRules={this.validationRules}
                    />
                );
            case 'diet-level':
                return (
                    <StepDietLevel
                        dietLevel={Number(this.state.dietLevel)}
                        psychotest={this.state.psychotest}
                    />
                );
            case 'activity-type':
                return (
                    <StepActivityType
                        activityType={this.state.activityType}
                        nextStepName={this.state.nextStepName}
                    />
                );
            case 'test':
                return (
                    <StepTest
                        psychotest={this.state.psychotest}
                        step={this.state.testStep}
                    />
                );
            case 'triangle':
                return (
                    <StepTriangle
                        errors={this.state.errors}
                        psychotest={this.state.psychotest}
                        triangleOfPower={this.state.triangleOfPower}
                    />
                );
            case 'activity-simple':
                return (
                    <StepActivitySimple
                        activityLevel={this.state.activityLevel}
                        setDefaultActivityLevel={this.setDefaultActivityLevel}
                    />
                );
            case 'start-date':
                return (
                    <StepStartDate
                        startDate={this.state.startDate}
                        errors={this.state.errors}
                        validateData={this.validateData}
                        preload={this.state.preload}
                        validationRules={this.validationRules}
                    />
                );
            default:
                return <StepBmr />;
        }
    };

    renderAction = () => (
        <StepProvider
            prevStep={() => this.prevStep}
            nextStep={() => this.nextStep}
            handleInputChange={this.handleInputChange}
            changeNextBtnState={this.changeNextBtnState}
            preload={this.state.preloadNextStep}
        >
            <StepActions
                isNextButtonDisabled={this.state.isNextButtonDisabled}
                currentStep={this.state.currentStep}
            />
        </StepProvider>
    );

    render() {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={`${PATH_DAY_PLAN_INDEX}/${formatIsoDate(new Date())}`}
                    targetTab="diet"
                />
            );
        }

        if (this.state.redirectTrainings) {
            global.localStorage.setItem(
                'diet-settings',
                JSON.stringify(this.createRequest())
            );
            return (
                <Redirect
                    to={{
                        pathname: PATH_ACTIVITIES_INDEX,
                        state: { fromDietSettings: true },
                    }}
                />
            );
        }

        return (
            <DietSettings
                progress={this.state.progress}
                renderAction={this.renderAction}
            >
                {() => (
                    <React.Fragment>
                        <StepProvider
                            prevStep={() => this.prevStep}
                            nextStep={() => this.nextStep}
                            handleInputChange={this.handleInputChange}
                            changeNextBtnState={this.changeNextBtnState}
                            preload={this.state.preload}
                        >
                            <div id="wrapper" className="wrapper">
                                {this.renderStep(this.state.currentStep)}
                            </div>
                        </StepProvider>
                    </React.Fragment>
                )}
            </DietSettings>
        );
    }
}

export default withDebounce(
    withLoading(withLocale(DietSettingsIndexContainer), DietSettingsPlaceholder)
);
