export default {
    menu: 'Menu',
    'menu/diet': 'Diät',
    'menu/home': 'Home',
    'menu/diet-plan': 'Diätplan',
    'menu/trainings': 'Trainingssessions',
    'menu/mindfulness': 'Balance',
    'menu/shop': 'Shop',
    'menu/blog': 'Blog',
    'menu/knowledge': 'Wissen',
    'menu/contact': 'Kontakt',
    'menu/activities': 'Aktivitäten',
    'menu/shopping-list': 'Einkaufsliste',
    'menu/measurements': 'Maße',
    'menu/diet/settings': 'Diät Einstellungen',
    'menu/fav-meals': 'Favoriten',
    'menu/logout': 'Ausloggen',
    'menu/login': 'Einloggen',
    'menu/settings': 'Einstellungen',
    'menu/account': 'Konto',
    logout: 'Ausloggen',
    save: 'Festhalten',
    cancel: 'Abbrechen',
    delete: 'Entfernen',
    next: 'Weiter',
    back: 'Zurück',
    print: 'Drucken',
    ok: 'OK',
    add: 'Hinzufügen',
    insert: 'Hinzufügen',
    all: 'Alle',
    new: 'Neu',
    'print-header': 'Diet & Training by Ann',
    'print-copyright1':
        'Für {{email}} ausgearbeiteter Diätplan, geschützt durch Urheberrechte.',
    'print-copyright2': 'To chyba trzeba i tak zmienic nie Niemieckie prawo',
    close: 'Schließen',
    buy: 'Kaufen',
    'data-saved': 'Gutgeschrieben',
    'error/message/generic': 'Etwas ist schief gelaufen',
    'error/message/network': 'Netzfehler aufgetreten',
    'error/message/offline': 'Fehlende Netzverbindung',
    'error/message/no-account': 'Sie haben noch keinen Account?',
    'error/additional-message/generic':
        'Der Fehler wurde gemeldet, wir werden schnellstmöglich an der Behebung arbeiten',
    'error/additional-message/not-found':
        'Unter dieser Internet Adresse existiert keine Seite.',
    'error/additional-message/user-not-found':
        'Wir haben Ihr Konto unter den registrierten Benutzern nicht gefunden.',
    'button/refresh': 'Seite neu laden',
    'button/go-back': 'Zurück',
    'button/try-again': 'Noch einmal versuchen',
    'button/get-access': 'Zugang erhalten',
    'button/return': 'Zurück',
    'button/send-email': 'E-Mail senden',

    week: 'Woche',
    week_plural: '{{count}} Wochen',
    month: 'Monat',
    month_plural: '{{count}} Monate',
    year: 'Jahr',
    year_plural: '{{count}} Jahre',
    'diet-intro/title': 'Vor dem Beginn durchlesen',
    'diet-intro/start-diet': 'Diät beginnen',
    'diet-intro/quote1':
        'Glückwunsch! Du bist durch die Entscheidung dich zu verändern bewusst zur Tat geschritten, die dich zu deinem Ziel führen wird.',
    'diet-intro/quote2':
        'Mit unserer Hilfe wirst du nicht nur deine Traumfigur erreichen sondern viel mehr!',
    'diet-intro/quote3':
        "Eine gesunde und an dich angepasste Diät hilft dir, dich gut in deiner eigenen Haut zu fühlen und gesund zu bleiben, ein Leben lang. Los geht's!",
    'diet-intro/sign': 'Anna Lewandowska und ihr Team',

    'diet-type/classic/lvl1': 'Classic Diät',
    'diet-type/classic/lvl2': 'Classic Diät',
    'diet-type/classic/lvl3': 'Classic Diät',

    'diet-type/classic/lvl1/sport': 'Classic Diät, SPORT',
    'diet-type/classic/lvl2/sport': 'Classic Diät, SPORT',
    'diet-type/classic/lvl3/sport': 'Classic Diät, SPORT',

    'diet-type/vege': 'Veggie Diät',
    'diet-type/smart': 'Smart Diät',

    'meal/preparation-time': 'Vorbereitungszeit',
    'diet/meal/seasoning/header': 'Gewürze',
    'diet/meal/optionalIngredients/header':
        'Zum würzen kannst du auch folgendes hinzufügen:',
    'meal/min': 'min.',
    'meal/how-to-prepare': 'Zubereitungsart',
    'meal/proteins-short': 'P',
    'meal/fat-short': 'F',
    'meal/carbohydrates-short': 'K',
    'meal/proteins': 'Protein',
    'meal/fat': 'Fett',
    'meal/weekday0': 'Sonntag',
    'meal/carbohydrates': 'Kohlenhydrate',
    'meal/weekday1': 'Montag',
    'meal/weekday2': 'Dienstag',
    'meal/weekday3': 'Mittwoch',
    'meal/weekday4': 'Donnerstag',
    'meal/weekday6': 'Samstag',
    'meal/weekday5': 'Freitag',
    'meal/weekday0-short': 'So.',
    'meal/weekday1-short': 'Mo.',
    'meal/weekday2-short': 'Di.',
    'meal/weekday3-short': 'Mi.',
    'meal/weekday4-short': 'Do.',
    'meal/weekday5-short': 'Fr.',
    'meal/weekday6-short': 'Sa.',
    'meal/exchange-meal-button': 'Ersetzen',
    'meal/exchange-dish-button': 'Ersetzen',
    'meal/exchange-collapse': 'Verstecken',
    'meal/exchange-day-button': 'Tag ersetzen',
    'meal/exchange-ingredient': 'Zutat ändern',
    'meal/exchange-for': 'Du kannst es ersetzen mit',
    'meal/exchange-section-title': 'Du kannst es ersetzen mit',
    'meal/original-product': 'Ausgangs Produkt',
    'meal/original-meal': 'Ausgangs Gericht',
    'meal/original-day': 'Ausgangs Tagesmenü',
    'meal/summary-title': 'Überblick',
    'meal/print-button': 'Drucken',
    'meal/print-period-alert': 'Druck für weniger als 2 Wochen verfügbar.',
    'meal/show-menus-contain': 'Zeige Tagesmenüs die folgendes beinhalten:',
    'meal/menus-contain': 'Beinhaltet:',
    'meal/search': 'Suchen',
    'meal/name-of-dish-or-ingredient': 'Name des Gerichts oder es beinhaltet',
    'meal/there-are-no-replacements':
        'Fehlen der entsprechenden Ersatz Lebensmittel.',
    'meal/supports': 'Unterstützt:',
    'next-day-notification': 'Für morgen vorbereiten:',
    'meal/copy-day-button': 'Kopie für morgen',
    'meal/copy-day-button-done': 'Kopiert',

    'alert/holiday-notification/button': 'Show menu',
    'meal/eaten-button': 'Verzehrt',
    'meal/ingredients': 'Zutaten',
    'meal/favourite-meals': 'Favoriten',
    'meal/show-favourite-meals': 'Suche nach Favoriten:',
    'meal/add-to-dayplan': 'Zum Ernährungsplan hinzufügen',
    'meal/meal-details': 'Essensdetails',
    'meal/favourite-meals/no-meals':
        'Füge Mahlzeiten zu deinen Favoriten hinzu, damit du sie immer griffbereit hast und sie bequem zu deinem Speiseplan hinzufügen kannst.',
    'meal/favourite-meals/no-search-results':
        'Keine Ergebnisse zu den Mahlzeiten gefunden.',

    'holiday-notification':
        'Zeige <u> spezielles Tagesmenü </u> für diesen Tag.',
    'dish/portion/you-should-have-this-dish-last':
        'Du solltest dieses Gericht schon vorher vorbereitet haben. Falls etwas über geblieben ist, wird dies nicht mehr in deinem Diätplan berücksichtigt.',
    'dish/portion/you-should-have-this-dish':
        'Du solltest dieses Gericht bereits vorbereitet haben. ',
    'dish/portion/rest': 'Der Rest wird nicht in deinem Diätplan verwendet.',
    'dish/portion/eat-x-of': 'Iss eine Portion von  {{total}}',
    'dish/portion/eat-x-of_plural':
        'Iss {{fraction}}&nbsp;Portionen von  {{total}}.',
    'dish/portion/eat-x-portion': 'Iss eine Portion',
    'dish/portion/eat-x-portion_plural': 'Iss {{fraction}}&nbsp;Portionen.',
    'dish/portion/x-portion-of': '- eine Portion von {{total}}',
    'dish/portion/x-portion-of_plural':
        ' - {{fraction}}&nbsp;Portionen von {{total}}',
    'recipe-for-x-portion': 'Umschreiben auf eine Portion',
    'recipe-for-x-portion_plural': 'Umschreiben auf {{count}} Portionen',
    'dish/portion/dish-used-in-following-days':
        'Dieses Gericht wird in den Folgetagen verwendet. Das Rezept bezieht sich auf eine Portion',
    'dish/portion/dish-used-in-following-days_plural':
        'Das Gericht wird in den Folgetagen verwendet. Das Rezept bezieht sich auf {{count}} Portionen.',
    'shopping-list/title': 'Einkaufsliste',
    'shopping-list/export-to-frisco':
        'Auf der Frisco.pl EInkaufsliste speichern.',
    'shopping-list/date-range': 'Datumsbereich',
    'shopping-list/show-list-button': 'Liste einblenden',
    'shopping-list/alert/no-full-covered-by-diet-in-period':
        'Du hast keine ausgelegte Diät für diesen Zeitumfang. Die Einkaufsliste beinhaltet Produkte nur für die Tage an denen die Diät gültig ist.',
    'shopping-list/alert/no-diet-in-period':
        'Fehlen der ausgelegten Diät für das angegebene Zeitfenster',
    'shopping-list/bought': 'Gekauft',
    'shopping-list/no-products': 'Keine Produkte',
    'shopping-list/print': 'Drucken Sie Ihre Einkaufsliste aus',
    'measurments/title': 'Maße',
    'measurments/current-measurments-title': 'Deine aktuellen Maße',
    'measurments/history-measurments-title': 'Historie der Messungen',
    'measurments/edit-measurments-title': 'Maße bearbeiten',
    'measurments/add-measurments-button': 'Maße eingeben',
    'measurments/delete-measurment-button': 'Aktuellen Wert löschen',
    'measurments/date': 'Daten',
    'measurments/alert-no-measurments-in-period':
        'Fehlen der angeforderten Werte für den definierten Zeitraum',
    'measurments/param/body-weight': 'Körpergewicht',
    'measurments/param/hips': 'Hüften',
    'measurments/param/hip': 'Hüfte',
    'measurments/param/waist': 'Taille',
    'measurments/param/thighs': 'Oberschenkel',
    'measurments/param/thigh': 'Oberschenkel',
    'measurments/param/chest': 'Brustkorb',
    'measurments/param/arm': 'Schultern',
    'measurments/state/no-measurement': 'Fehlende Maße',
    'measurments/state/change': 'Ändern',
    'measurments/state/no-change': 'Unverändert',
    'no-data': 'Fehlende Angaben',
    'no-change': 'Unverändert',
    'redirect/diet-settings/title': 'Diätplan',
    'redirect/diet-settings/message': 'Du hast noch keinen begonnenen Diätplan',
    'redirect/shopping-list/message':
        'Beginne deine Diät, um die Einkaufsliste einsehen zu können',
    'redirect/diet-settings/button': 'Beginne die Diät',
    'diet-settings/title': 'Diät Einstellungen',
    'diet-settings/sex': 'Geschlecht',
    'diet-settings/sex/male': 'Mann',
    'diet-settings/sex/female': 'Frau',
    'diet-settings/name': 'Name',
    'diet-settings/current-body-weight': 'Aktuelles Körpergewicht',
    'diet-settings/height': 'Größe',
    'diet-settings/birth-date': 'Geburtsdatum',
    'diet-settings/your-goal': 'Dein Ziel',
    'diet-settings/goal/slimming': 'Gewichtsreduktion',
    'diet-settings/goal/stabilization': 'Gewichterhaltung',
    'diet-settings/goal/put-on-weight': 'Gewichtszunahme',
    'diet-settings/goal/gain-muscle-mass': 'Muskelaufbau',
    'diet-settings/goal-weight': 'Zielgewicht',
    'diet-settings/triangle/intro':
        'Wähle die Bereiche, die deine Diät unterstützen soll',
    'diet-settings/triangle/body': 'Körper',
    'diet-settings/triangle/body/desc':
        'Ein gesunder Körper ist leistungsfähig und Resistent gegen schädliche Einwirkungen von außen. Wenn du dazu die Hilfe eines Ernährungsberaters benötigst, kannst du diese Option wählen.',
    'diet-settings/triangle/mind': 'Geist',
    'diet-settings/triangle/mind/desc':
        'Wenn du täglich auf höchstem Level geistige Arbeit verrichten musst und die keine Patzer erlauben darfst, dann ist das die richtige Option für dich.',
    'diet-settings/triangle/libido': 'Libido',
    'diet-settings/triangle/libido/desc':
        'Durch die Wahl dieses Schwerpunktes wirst du in deiner Diät nicht nur Lebensmittel vorfinden, die seit Ewigkeiten als Aphrodisiakum bekannt sind sondern auch welche, die indirekt auf deinen Organismus einwirken indem sie durchblutungsfördernd wirken.',
    'diet-settings/diet-type/question': 'Diät Modus',
    'diet-settings/diet-type/classic': 'Diet by Ann Classic - 5 Mahlzeiten',
    'diet-settings/diet-type/classic/description':
        'Schließt Weizenprodukte und Kuhmilch aus. Proteinquelle sind hauptsächlich Fleisch, Fisch, Milchprodukte (nicht auf Kuhmilch Basis) und Eier.',
    'diet-settings/diet-type/smart': 'Diet by Ann Smart - 3 Mahlzeiten',
    'diet-settings/diet-type/smart/description':
        'Das ist die schnellste und einfachste Diätvariante. Sie unterstützt die Wirkung des Energiedreiecks und schließt gleichzeitig sämtliche Weizenprodukte und Kuhmilch aus. Wähle diese Variante aus, wenn du möglichst wenig Zeit in der Küche verbringen willst.',
    'diet-settings/diet-type/vege': 'Diet by Ann Veggie - 5 Mahlzeiten',
    'diet-settings/diet-type/vege/description':
        'Schließt Fleisch, Fisch und Meeresfrüchte, Weizenprodukte und Kuhmilch aus. Beinhaltet aber Käse, fermentierte Milchprodukte, Eier und Hülsenfrüchte.',
    'diet-settings/activity/question': 'Wie viel trainierst du pro Woche?',
    'diet-settings/activity/none': 'Ich trainiere gelegentlich oder gar nicht',
    'diet-settings/activity/none/description': 'Büroarbeit',
    'diet-settings/activity/low': 'Ich trainiere 1-3 Stunden die Woche',
    'diet-settings/activity/medium': 'Ich trainiere 3-5 Stunden die Woche',
    'diet-settings/activity/medium/description': 'Krafttraining',
    'diet-settings/activity/high': 'Ich trainiere 3-5 Stunden wöchentlich',
    'diet-settings/activity/high/description':
        '(aerobes Training, Joggen, Spinning, usw.)',
    'diet-settings/final/text':
        'Worauf muss ich achten beim Beginn oder Änderung der Diät?',
    'diet-settings/final/list/1':
        'Plane Zeit ein für die Einkäufe der für die Diät notwendigen Lebensmittel',
    'diet-settings/final/list/2':
        'Wenn du eine längere Abwesenheit planst, in der du nicht auf die Speisepläne zurückgreifen kannst, solltest du die Diät erst auf einen anderen Zeitpunkt verschieben.',
    'diet-settings/final/list/3':
        'Verschiebe nicht den Starttermin – du hast den ersten Schritt bereits gewagt, mache jetzt mutig weiter',
    'diet-settings/final/list/4': 'Du hast 15 Tage für den Start der Diät',
    'diet-settings/final/start-date-question':
        'Ab wann willst du deine Diät beginnen bzw. sie ändern?',
    'diet-settings/diet-level/question': 'Komplexitätsgrad deiner Diät',
    'diet-settings/diet-level/level1': 'Diät HPBA Level 1: Guter Start',
    'diet-settings/diet-level/level1/description':
        'Diese Diät ist für dich ideal wenn du keine Weizenprodukte und keine Kuhmilch zu dir nimmst, du allerdings keine Allergien auf Kuhmilch-Eiweiße hast. Wähle diese Diät falls du die oben erwähnten Produkte aus deiner Diät ausschließen möchtest, auch aus gesundheitlichen Gründen.',
    'diet-settings/diet-level/level2':
        'Diät HPBA Level 2: Der Weg zur Meisterschaft',
    'diet-settings/diet-level/level2/description':
        'Diese Diät ist für dich ideal wenn du Gluten und Milchprodukte von deinem täglichen Speiseplan streichst. Wähle diese Diät aus wenn du die oben erwähnten Produkte ausschließen willst, auch aus gesundheitlichen Gründen.',
    'diet-settings/diet-level/level3':
        'Diät HPBA Level 3: Herausforderung für die Kämpferin',
    'diet-settings/diet-level/level3/description':
        'Diese Diät ist ideal für dich wenn du Gluten, alle Milchprodukte als auch Hülsenfrüchte auf Grund von Allergien oder anderen gesundheitlichen Gründen aus deiner Diät ausschließen willst oder dich einfach gesund ernähren willst.',
    'diet-settings/activity-type/question': 'Diät Modus',
    'diet-settings/activity-type/classic': 'Diet by Ann – Version Classic',
    'diet-settings/activity-type/classic/description':
        'Diät für Menschen mit niedrigem Aktivitätslevel und für unregelmäßig trainierende bzw. gar nicht Trainierende.',
    'diet-settings/activity-type/trainings': 'Diet by Ann SPORT',
    'diet-settings/activity-type/trainings/description':
        'Diät für Menschen mit hohem Aktivitätslevel und regelmäßig Trainierende.  Berücksichtigt die Art, Umfang und Uhrzeit des Trainings als auch Snacks um das Training herum.',
    'diet-settings/psychotest1/question':
        'Wähle eine Umschreibung, die am meisten auf dich zutrifft',
    'diet-settings/psychotest1/answer1':
        'Ich führe ein sehr intensiven Lebensstil, achte nicht besonders auf meine Ernährung, nehme oft fertige Gerichte oder Lebensmittelprodukte in Anspruch, habe keine Zeit mir um meine Ernährung Gedanken zu machen.',
    'diet-settings/psychotest1/answer2':
        'Ich bemühe mich einen gesunden Lebensstil zu führen aber es fehlt mir oft das Durchhaltevermögen. Es ist mühsam, die ganze Zeit im Blick haben zu müssen, was man essen darf und was nicht. Ich brauche neue Ideen.',
    'diet-settings/psychotest1/answer3':
        'Ich denke, dass ich mich sehr gesund ernähre aber trotzdem fühle ich mich nicht immer gut.',
    'diet-settings/psychotest2/question':
        'Wie ist dein allgemeines Wohlempfinden (berücksichtige den letzten Monat)?',
    'diet-settings/psychotest2/answer1':
        'Ich fühle mich gut und habe keinerlei Beschwerden',
    'diet-settings/psychotest2/answer2':
        'Mal besser mal schlechter, ich bin manchmal erschöpft und überbelastet.',
    'diet-settings/psychotest2/answer3':
        'Ich stecke voller Energie und fühle mich großartig!',
    'diet-settings/psychotest3/question':
        'Schließt du schon Gluten aus deiner Diät aus?',
    'diet-settings/psychotest3/answer1': 'Ja',
    'diet-settings/psychotest3/answer2': 'Nein',
    'diet-settings/psychotest3/answer3':
        'Ich versuchte es zu begrenzen allerdings habe ich es nicht vollständig gemacht.',
    'diet-settings/psychotest4/question':
        'Hast du schon Kuhmilch aus deiner Diät ausgeschlossen?',
    'diet-settings/psychotest4/answer1': 'Ja',
    'diet-settings/psychotest4/answer2': 'Nein',
    'diet-settings/psychotest4/answer3':
        'Ich versuchte es zu begrenzen allerdings habe ich es nicht vollständig gemacht.',
    'diet-settings/psychotest5/question':
        'Wähle die aktuell höchste Herausforderung an deinen Körper:',
    'diet-settings/psychotest5/answer1': 'Wachstum des körperlichen Potentials',
    'diet-settings/psychotest5/answer2': 'Bekämpfung geistiger Erschöpfung',
    'diet-settings/psychotest5/answer3': 'Optimierung der Vitalkräfte',
    'diet-settings/psychotest6/question':
        'In welcher Aussage findest du dich am meisten wieder?',
    'diet-settings/psychotest6/answer1':
        'Ich habe Konzentrationsschwierigkeiten.',
    'diet-settings/psychotest6/answer3': 'Keine der vorgeschlagenen.',
    'diet-settings/psychotest6/answer2':
        'Ich bin ohne Probleme geistig auf Hochtour.',
    'diet-settings/psychotest7/question':
        'Wähle das für dich am meisten in Frage kommende:',
    'diet-settings/psychotest7/answer1': 'Ich führe ein aktives Leben',
    'diet-settings/psychotest7/answer2':
        'Mein Tag ist sehr ruhig und geregelt.',
    'diet-settings/psychotest7/answer3':
        'Wenn ich mich ins Bett legen, schlafe ich sofort ein.',
    'recommended-on-psychotest': '(Auf Grundlage des Tests empfohlene)',
    'settings/title': 'Einstellungen',
    'settings/profile': 'Profil',
    'settings/email': 'E-Mail-Adresse',
    'settings/metric-system': 'Metrisches System',
    'settings/si': 'SI System',
    'settings/imperial': 'Imperiales System',
    'settings/notifications': 'Benachrichtigungen',
    'settings/agreements/diet-course': 'Bezüglich des Diät-Verlaufs',
    'settings/agreements/articles': 'Motivation und Artikel',
    'settings/agreements/newsletter': 'Newsletter',
    'settings/agreements/newsletter-info':
        'Das Abbestellen des Newsletters kann bis zu 2 Stunden in Anspruch nehmen.',
    'settings/change-password': 'Kennwort ändern',
    'settings/change-password/current-password': 'Aktuelles Kennwort',
    'settings/change-password/new-password': 'Neues Kennwort',
    'settings/change-password/success': 'Kennwort wurde geändert',
    'settings/products/access/past-end-date {date}':
        'Dein Zugang ist abgelaufen {date}',
    'settings/products/access/end-date {date}': 'Zugang bezahlt bis {date}',
    'settings/products/access/from-subscription':
        'Zugang auf Basis der Subskription.',
    'settings/products/access/lifetime': 'Lebenslanger Zugang',
    'settings/products/access/none': 'Fehlt.',
    'settings/products/diet/heading': 'Diät',
    'settings/products/heading': 'Aktive Produkte',
    'settings/products/video-workouts/heading': 'Trainingssessions',
    'settings/subscriptions/cancellation-instructions':
        'Anleitung zum Annullieren',
    'settings/subscriptions/heading': 'Aktive Subskriptionen',
    'settings/subscriptions/diet/heading': 'Diät',
    'settings/subscriptions/video-workouts/heading': 'Trainingssessions',
    'settings/subscriptions/diet+video-workouts/heading':
        'Diäten und Trainingssessions',
    'settings/subscriptions/none': 'Fehlt.',
    'settings/subscriptions/renewal-date {date}':
        'Nächster Subskriptionsstart: {date}.',
    'settings/subscriptions/renewal-date/in-past':
        'Erneuerung im Zuge der Verarbeitung.',
    'settings/subscriptions/type/diet+video-workouts':
        'Zugang zu den Diäten und Trainingssessions',
    'settings/subscriptions/type/diets': 'Zugang zur Diät',
    'settings/subscriptions/type/video-workouts':
        'Zugang zu den Trainingssessions',
    'settings/hydration': 'Flüssigkeitszufuhr',
    'settings/hydration/daily-goal': 'Tägliches Ziel',
    'settings/hydration/success': 'Ziel erreicht',
    'settings/hydration/notifications': 'Ans Wasser trinken erinnern',
    'settings/hydration/notifications/hour-interval': 'Alle wie viel Stunden',
    'settings/hydration/notifications/from-hour': 'Ab Stunde',
    'settings/hydration/notifications/to-hour': 'Bis Stunde',
    'settings/hydration/notifications/use-sound': 'Mit Ton',
    'settings/remove-account': 'Konto Löschen',

    'remove-account/title': 'Konto Löschen',
    'remove-account/app-store/disclaimer':
        '<b>Achtung! Durch das Löschen des Kontos wird das Abonnement nicht gekündigt.</b> Du kannst aktive Abonnements in Deinem Apple-ID-Kontoeinstellungen auf Deinem iPhone verwalten. ',
    'remove-account/google-play/disclaimer':
        '<b>Achtung! Durch das Löschen eines Kontos wird das Abonnement nicht gekündigt.</b> Du kannst aktive Abonnements in den Einstellungen der Google Play App verwalten.',
    'remove-account/huawei/disclaimer':
        '<b>Achtung! Durch das Löschen des Kontos wird die Subskription nicht gekündigt.</b> Sie können aktive Subskriptionen in den Kontovervaltung auf Ihrem Telefon verwalten.',
    'remove-account/email-confirm-info':
        'Der Vorgang des Löschen eines Kontos erfordert eine Überprüfung Deines Kontos. Dazu senden wir Dir eine E-Mail an folgende Adresse: {{userEmail}}',
    'remove-account/message-sent': 'Nachricht senden',
    'remove-account/link-expire-info': 'Der Link läuft nach 24 Stunden ab.',
    'remove-account/check-your-mailbox':
        'Überprüfe Deinen Posteingang. Klicke auf den Link in der Nachricht, die wir Dir gesendet haben. <br /> <b>Dein Konto wird erst gelöscht, wenn Du dies tust.</b>',
    'remove-account/confirmation':
        'Ich bestätige, ich möchte mein Konto aus der Anwendung löschen.',
    'remove-account/info-title': 'Nach dem Löschen des Kontos:',
    'remove-account/info_1':
        'kannst Du Dich nicht merh auf Deinem Konto einloggen, wodurch Du Zugriff auf die Anwendung verlierst',
    'remove-account/info_2':
        'Du verlierst Deinen gesamten Fortschritt, Verlauf, Belohnungen usw. in Bezug auf Training, Ernährung und andere Aktivitäten in der App',
    'remove-account/info_3': 'Deine Kontodaten werden unwiderruflich gelöscht ',
    'remove-account/error': 'Fehler aufgetreten',
    'remove-account/try-in-moment': 'Bitte versuche es gleich noch einmal.',
    'remove-account/link-expired': 'Der Link ist abgelaufen.',
    'remove-account/link-expired-info':
        'Wiederhole den Kontolöschvorgang in der App, um eine neue E-Mail zu erhalten.',
    'remove-account/success': 'Dein Account wurde gelöscht',
    'remove-account/success-info':
        'Du wirst in Kürze automatisch von der App abgemeldet.',

    'footer/service-access': 'Zugang zum Dienst und zur Zahlung',
    'footer/tos': 'AGB',
    'footer/privacy-policy': 'Datenschutz',
    'footer/impressum': 'Impressum',
    'footer/contact': 'Kontakt',
    'footer/secure-payments': 'Sichere Zahlung durch',
    'button/buy-diet': 'Erwirb einen Zugang zur Diät',
    'button/buy-subscribtion': 'Subskription erwerben',
    'button/buy': 'Kaufen',
    'button/remove-account': 'Konto Löschen',
    'button/retry': 'Wiederholen',

    'error/unauthorized/subscription/expired/title':
        'Deine Subskription ist abgelaufen \n{{accessTo}}',
    'error/unauthorized/subscription/expired/content':
        'Führe deine Diät fort indem du das Stammkunden Angebot in Anspruch nimmst. ',
    'error/unauthorized/subscription/never-had/title':
        'Beginne deine Diät am besten gleich heute',
    'error/unauthorized/subscription/never-had/content':
        'Nur noch ein Schritt zum Beginn der Diät. Hab keine Angst vor neuen Herausforderungen. Bestimme dein Ziel und Kämpfe um es. Ich helfe dir bei der Diät und glaube daran, dass wir es mit vereinten Kräften schaffen. Verliere keine Zeit! Sollen wir loslegen?',
    'error/unauthorized/subscription/refresh': 'Nochmals prüfen',
    'info-pages/goal-reached/title': 'Sehr gut! Du hast dein Ziel erreicht.',
    'info-pages/goal-reached/content':
        'Jetzt kannst du die stabilisierende Diät beginnen, welche dir dabei helfen wir den bisherigen Effekt zu halten. Du hast immer die Möglichkeit weiter abzunehmen indem du dein Wunschgewicht weiter reduzierst.',
    'info-pages/goal-reached/button': 'Diätplan anpassen',
    'info-pages/goal-lost/title': 'Ärgerlich! Du hast dein Ziel verpasst',
    'info-pages/goal-lost/content':
        'Um zu deinem Wunschgewicht zurück zu kehren empfehle ich den Wechsel zu einer Abnehm-Diät.',
    'info-pages/goal-lost/button': 'Diätplan ändern',
    'info-pages/current-weight-needed/title': 'Aktuelles Gewicht hinzufügen',
    'info-pages/current-weight-needed/content':
        'Um den Diätplan weiter erstellen zu können musst du uns dein aktuelles Körpergewicht mitteilen.',
    'info-pages/current-weight-needed/button': 'Gewicht hinzufügen',
    'info-pages/diet-available-in-future/title':
        'Dein Diätplan wird in {{count}} Tagen verfügbar sein',
    'info-pages/diet-available-in-future/title_plural':
        'Dein Diätplan wird in {{count}} Tagen verfügbar sein',
    'info-pages/diet-available-in-future/content':
        'Der neue Diätplan kann frühestens 7 Tage vor dem Beginn der Diät erstellt werden. Denke daran in dieser Zeit dein Körpergewicht einzutragen.',
    'info-pages/diet-outside-access/title':
        'Deine Subskription endet in einem Tag, {{date}}',
    'info-pages/diet-outside-access/title_plural':
        'Deine Subskriptionen endet in {{count}} Tagen, {{date}}',
    'info-pages/diet-outside-access/unknown-number': 'Anzahl unbekannt',
    'info-pages/diet-outside-access/web-title':
        'Deine Abonnement endet in einem Tag,  {{date}}',
    'info-pages/diet-outside-access/web-title_plural':
        'Deine Abonnement endet in {{count}} Tagen,  {{date}}',
    'contact/title': 'Kontakt',
    'contact/faq': 'Häufig gestellte Fragen',
    'contact/contact-form': 'Kontakt-Formular',
    'contact/name-surname': 'Vor- und Nachname',
    'contact/email-address': 'Deine E-Mail-Adresse',
    'contact/topic': 'Inhalt der Nachricht',
    'contact/topic/topic1': 'Problem mit dem Erwerb des Diätplans',
    'contact/topic/topic2': 'Frage an einen Ernährungsberater',
    'contact/topic/topic3': 'Frage zum Training',
    'contact/topic/topic4': 'Problem mit dem Zugang zum Service',
    'contact/topic/topic5': 'Diät-Plan - Reklamation und Widerruf',
    'contact/topic/topic6': 'Internet Shop – Reklamation und Widerruf',
    'contact/topic/topic7': 'Zusammenarbeit',
    'contact/message': 'Nachricht',
    'contact/send-message': 'Nachricht senden',
    'contact/send-success':
        'Deine Nachricht wurde verschickt. Wir bedanken uns.',
    'knowledge/title': 'Wissen',
    'knowledge/show': 'Anzeigen:',
    'knowledge/all': 'Alles',
    'knowledge/diet': 'Diät',
    'knowledge/motivation': 'Motivation',
    'knowledge/tips': 'Ratschläge',
    'knowledge/excercise': 'Übungen',
    'knowledge/back-to-article-list': 'Zurück zur Produktliste',
    'knowledge/article-details': 'Artikeldetails',
    'knowledge/back': 'Zurück',
    'knowledge/other-articles': 'Andere Artikel',
    'knowledge/read-more': 'Mehr anzeigen',
    'knowledge/no-results': 'Keine Ergebnisse',
    'knowledge/load-more': 'Weitere Artikel',
    'me/account/activate/message': 'Dein Konto wurde aktiviert',
    'make-decision/title': 'Entscheide dich',
    'make-decision/start': 'Start',
    'make-decision/now': 'Derzeit',
    'make-decision/goal': 'Ziel',
    'make-decision/left': 'Verblieben',
    'make-decision/put-on-weight': 'Zunehmen',
    'make-decision/gain-muscle-mass': 'Muskelaufbau',
    'make-decision/weight-loose': 'Abnehmen',
    'make-decision/stabilization': 'Stabilisierung',
    'make-decision/continue-slimming':
        'Wenn du weiter Abnehmen möchtest, wähle dein neues Wunschgewicht aus.',
    'make-decision/change-diet-mode-from-slimming-to-stabilization':
        'Du hast dein Zielgewicht erreicht. Wähle den Starttag der stabilisierenden Diät, welche dir hilft dein neues Gewicht zu halten.',
    'make-decision/continue-stabilization':
        'Drücke auf die untere Schaltfläche falls du trotz der Veränderung des Körpergewichts die stabilisierende Diät weiter beibehalten möchtest. Bitte denke daran, dass die letzte Gewichtsmessung dabei als dein aktuelles Gewicht genutzt wird.',
    'make-decision/change-diet-mode-from-stabilization-to-slimming':
        'Da du zugenommen hast, empfehlen wir den Beginn einer Abnehm-Diät. Trage dein neues Zielgewicht und den Starttag der Diät ein.',
    'make-decision/change-diet-mode-from-stabilization-to-put-on-weight':
        'Da du abgenommen hast, empfehlen wir dir mit einer Diät mit dem Ziel des Zunehmens anzufangen. Trage dein neues Zielgewicht und den Starttag der Diät ein.',
    'make-decision/continue-put-on-weight':
        'Wenn du den Diätplan zum Zunehmen weiter verfolgen möchtest, trage dein neues Wunschgewicht ein.',
    'make-decision/change-diet-mode-from-put-on-weight-to-stabilization':
        'Du hast dein Zielgewicht erreicht. Wähle den Starttag der stabilisierenden Diät, welche den Effekt absichern wird.',
    'make-decision/continue-gain-muscle-mass':
        'Wenn du weiter Muskelmasse aufbauen willst, trage dein neues Wunschgewicht ein.',
    'make-decision/change-diet-mode-from-gain-muscle-mass-to-stabilization':
        'Du hast dein Zielgewicht erreicht. Wähle den Starttag der stabilisierenden Diät, welche den Effekt absichern wird.',
    'route-title/default': 'Diet & Training by Ann',
    'route-title/day-plan': 'Tagesplan',
    'route-title/diet-intro': 'Zugang',
    'route-title/day-plan-with-date': 'Tagesplan',
    'route-title/diet-will-be-available-in-future':
        'Die Diät wird bald zugänglich sein',
    'route-title/current-weight-needed': 'Aktuelles Gewicht hinzufügen',
    'route-title/goal-reached': 'Ziel erreicht',
    'route-title/goal-lost': 'Ziel verfehlt',
    'route-title/make-decision': 'Entscheide dich',
    'route-title/shopping-list': 'Einkaufsliste',
    'route-title/measurements': 'Maße',
    'route-title/measurements-add': 'Maße eingeben',
    'route-title/measurements-edit': 'Maße bearbeiten',
    'route-title/diet-settings': 'Diät Einstellungen',
    'route-title/settings': 'Einstellungen',
    'route-title/login-by-token': 'Mit Token einloggen',
    'route-title/logout': 'Ausloggen',
    'route-title/error-404': 'Fehler 404',
    'route-title/unauthorized-exception': 'Kein Zugang',
    'route-title/content': '',
    'route-title/contact': 'Kontakt',
    'route-title/knowledge-category': 'Wissen',
    'route-title/knowledge': 'Wissen',
    'route-title/knowledge-article': '',
    'route-title/quiz': 'Quiz',
    'route-title/diet-outside-access': 'Kein Zugang',
    'route-title/block-cookies': '',
    'route-title/error-network': 'Netzfehler aufgetreten',
    'route-title/activities': 'Meine Aktivitäten',
    'route-title/activities-index': 'Aktivitäten',
    'route-title/activities-add': 'Aktivitäte hinzufügen',
    'route-title/activities-edit': 'Aktivitäten bearbeiten',
    'route-title/promo-code': 'Rabatt Coupon',
    'route-title/unsubscribe': 'Schade dass du uns verlässt',
    'route-title/error-application': 'Fehler in der Applikation',
    'route-title/mindfulness': 'Balance',
    'route-title/fav-meals': 'Favoriten',
    'mobile-title/knowledge': 'Wissen',
    'alert/periodic-measurements':
        '<b>Stellen Sie sicher, dass Sie Ihre aktuellen Messungen hinzufügen</b>, um Ihren Fortschritt zu verfolgen.',
    'alert/periodic-measurements-1':
        'Regelmäßiges wiegen ist notwendig, um deine Fortschritte besser zu verfolgen.',
    'alert/periodic-measurements-2':
        'Wir erinnern daran dich an die Notwendigkeit sich zu wiegen, am besten noch heute.',
    'auth/confirm/header': 'Wir danken dir!',
    'auth/confirm/content':
        'Dein Konto wurde aktiviert. Du kannst die App Diet & Training by Ann nun nutzen.',
    'auth/confirm/mobileApps':
        'Du hast die App noch nicht? Lade sie jetzt herunter!',
    'auth/login/title': 'Einloggen',
    'auth/login/with-google': 'Mit Google weitermachen',
    'auth/login/with-facebook': 'Mit Facebook weitermachen',
    'auth/login/with-apple': 'Mit Apple weitermachen',
    'auth/login/user-exists':
        'Das Konto existiert bereits. Nutze die Einlog-Funktion',
    'auth/login/user-not-exists':
        'Konto existiert nicht. Nutze die Registrierungsfunktion.',
    'auth/login/agreement':
        'Mit dem Einloggen akzeptierst du die aktuellen AGB des Dienstes. Die letzten Änderungen an den AGB wurden am 03. April 2019 vorgenommen.',
    'auth/login/back-link-text': 'Zurück zum Einloggen',
    'auth/login/menu-link-text': 'Einloggen',
    'auth/login/error-message': 'Einloggen hat nicht geklappt',
    'auth/login/social-media-login-header': 'Einloggen über soziale Netzwerke',
    'auth/login/social-media-set-up':
        'Dein Konto ist mit einem sozialen Netzwerk verbunden',
    'auth/remind-password/title': 'Kennwort wiedererlangen',
    'auth/remind-password/description':
        'Erhalte eine E-Mail mit Informationen zur Änderung des Passworts',
    'auth/remind-password/link-text': 'Kennwort vergessen',
    'auth/remind-password/error-message':
        'Kein User mit dieser E-Mail-Adresse gefunden. ',
    'auth/remind-password/success-message':
        'Email mit Anleitung zur Kennwort Änderung ist verschickt worden.',
    'auth/reset-password/title': 'Bestimme ein neues Kennwort',
    'auth/reset-password/token-is-not-valid-error-message':
        'Deine E-Mail zur Erstellung eines neuen Kennworts ist nicht mehr gültig. Falls du diese Option aber noch nutzen möchtest solltest du wieder auf "Kennwort vergessen" klicken',
    'auth/reset-password/error-message':
        'Das Kennwort sollte mindestens aus 4 Zeichen bestehen.',
    'auth/reset-password/success-message':
        'Kennwort zu deinem Konto ist gesetzt.',
    'auth/password-is-not-set':
        'Du hast noch kein Kennwort. Klicke unten um ein Kennwort zu bestimmen.',
    'auth/set-password': 'Kennwort setzen',
    'auth/set-password/title': 'Kennwort setzen',
    email: 'E-Mail-Adresse',
    password: 'Passwort',
    'password-to-login': 'Kennwort',
    'new-password': 'Neues Kennwort',
    name: 'Vor- und Nachname',
    'form/send': 'Senden',
    'validation/required': 'Ausfüllen erforderlich',
    'validation/date-format': 'Datumsformat ist ungültig',
    'validation/email-format': 'Die E-Mail-Adresse ist ungültig.',
    'validation/url-format': 'Urlformat ist ungültig',
    'activities/intro/title': 'Aktivitätsplan',
    'activities/intro/heading1':
        'Für wen sind die Trainingseinheiten im Diätplan?',
    'activities/intro/paragraph1':
        'Trainingseinheiten im Diätplan ermöglichen eine besser Anpassungen der Gerichte zu deinem Aktivitätslevel. Sie sind für regelmäßig Trainierende mit konkreten Trainingszielen gedacht.',
    'activities/intro/heading2':
        'Was ändert sich in den Diät-Plänen nach der Aktivierung der Trainingseinheiten?',
    'activities/intro/paragraph2':
        'Dein Ernährungsplan wird neu erstellt und wird den neuen Trainingsplan berücksichtigen. In den Menüplänen finden sich spezielle pre und post-workout Snacks und der Diätplan wird neu angepasst in Hinsicht auf den Kalorienmenge als auch den Nährwert',
    'activities/intro/button': 'Aktiviere den Trainingsplan',
    'activities/my-activities': 'Meine Aktivitäten',
    'activities/edit-activities': 'Aktivitäten verändern',
    'activities/disclaimer':
        'Bitte denk daran, dass jede Änderung (hinzufügen oder streichen einer Trainingseinheit) eine Neuanpassung des Diätplans bewirkt, die sich in einer Änderung der verwendeten Produkte äußert.',
    'activities/total': 'Zusammen:',
    'activities/diet-plan': 'Diätplan',
    before_breakfast: 'Vor dem Frühstück',
    after_breakfast: 'Nach dem Frühstück',
    before_dinner: 'Vor dem Mittagessen',
    after_dinner: 'Nach dem Abendessen',
    before_supper: 'Vor dem Abendessen',
    after_supper: 'Nach dem Abendessen',
    'activities/start-diet': 'Beginne',
    'activities/diet-settings': 'Diät-Einstellungen',
    'activities/add-activity': 'Aktivitäte hinzufügen',
    'activities/add-activity-single': 'Aktivitäte hinzufügen',
    'activities/add-activity-periodic':
        'Füge eine wiederkehrende Aktivität ein',
    'activities/edit-activity': 'Aktivitäten bearbeiten',
    'activities/edit-single-activity': 'Aktivitäten bearbeiten',
    'activities/edit-periodic-activity': 'Editiere alle Aktivitäten im Zyklus',
    'activities/remove-single-activity': 'Aktivität löschen',
    'activities/remove-periodic-activity':
        'Editiere alle Aktivitäten im Zyklus',
    'activities/time-of-day': 'Tagesplan',
    'activities/type-of-activity': 'Art der Aktivität',
    'activities/more': 'Mehr',
    'activities/activity-time': 'Dauer der Aktivität',
    'activities/burned-calories': 'Energieumsatz',
    'activities/repeat-every': 'Wiederhole jeden',
    'activities/activity1': 'Laufen/schnelles Gehen',
    'activities/activity2': 'Übungen',
    'activities/activity3': 'Fahrrad fahren',
    'activities/activity4': 'Schwimmen',
    'activities/duration': 'Dauer',
    'activities/training': 'Training',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
    'promo-code/page-title': 'Rabatt Coupon eingeben',
    'promo-code/input-label': 'Rabatt Coupon',
    'promo-code/missing-value': 'Rabatt Coupon eingeben',
    'promo-code/invalid-value': 'Coupon nicht gültig',
    'promo-code/submit': 'Coupon verifizieren',
    'promo-code/not-exists': 'Promotions Code ist nicht korrekt',
    'promo-code/has-been-activated': 'Promotions Code ist aktiviert',
    'quiz/intro/title': 'Wie gut kennst du meine Diät?',
    'quiz/intro/copy':
        'Beantworte 10 fragen, überprüfe dein Ergebnis und erhalte Zugang zu Power Rezepten!',
    'quiz/intro/quote':
        'Denkst du, dass alle die großes erreicht haben ein Talent dazu hatten? Der Großteil aller Erfolge beruht auf dem Glauben dass man es schafft, dass du es schaffst. Hör nicht bei Überlegungen auf, schreite zur Tat! Ich bin bei dir.',
    'quiz/intro/sign': 'Anna Lewandowska',
    'quiz/intro/numbers1': 'Anzahl der Quizfragen',
    'quiz/intro/numbers2': 'Mittlere Lösungsdauer',
    'quiz/intro/numbers3': 'Mittelgutes Quizergebnis',
    'quiz/start-link': 'Beginne das Quiz',
    'quiz/title': 'Quiz',
    'quiz/question/previous': 'Vorherige Frage',
    'quiz/receive-prize': 'Testergebnis einsehen',
    'quiz/receive-prize/button': 'Quiz Ergebnis erfahren',
    'quiz/receive-prize/title':
        'Gib deine E-Mail an, bekomme die Testergebnisse und erhalte deinen Gewinn',
    'quiz/receive-prize/leave-email':
        'Gib deine E-Mail-Adresse an um folgendes zu erhalten',
    'quiz/receive-prize/argument1':
        'Zugang zum Ratgeber mit Power Rezepten von Anna Lewandowska',
    'quiz/receive-prize/argument2':
        'Quizergebnisse mit gekennzeichneten Korrekturen und deinen Antworten',
    'quiz/result/title': 'Quizergebnisse',
    'quiz/result/your-result': 'Dein Ergebnis des Tests:',
    'quiz/result/your-answer': 'Deine Antworten im Quiz',
    'quiz/result/button': 'Lade den Ratgeber kostenlos herunter',
    'quiz/result/score0-3':
        'Es ist nicht schlecht aber es kann immer besser sein. Das beste, was du jetzt machen kannst, ist es nicht aufzuhören. Der Anfang ist oft schwer aber ich bin bei dir. Ich weiß dass du erfolgreich sein wirst.',
    'quiz/result/score4-6':
        'Von gesünder Ernährung weißt du schon wirklich eine ganze Menge 🙂 aber setzt du das was du weißt auch in die Tat um? Falls nicht, kein Problem. Ich habe eine Diät genau für solche Fälle konzipiert!',
    'quiz/result/score7-10':
        'Sehr gut! Du kennst dich sehr gut aus! Willst du mit mir zusammen das höchste Level gesunder Ernährung erreichen?',
    'quiz/result/quote':
        'Es ist nicht schlecht aber es kann immer besser sein. Das beste, was du jetzt machen kannst, ist es nicht aufzuhören. Der Anfang ist oft schwer aber ich bin bei dir. Ich weiß dass du erfolgreich sein wirst.',
    'quiz/result/sign': 'Anna Lewandowska',
    'quiz/result/get-your-guide': 'Lade den Ratgeber kostenlos herunter',
    'quiz/result/open-guide': 'Öffne den Ratgeber',
    'exitup/title':
        'Prüfe wie viel du über das Thema gesunde Ernährung weißt – mache das Quiz!',
    'exitup/content':
        'Als Preis erhältst du Power Rezepte von Anna Lewandowska',
    'exitup/button': 'Beginne das Quiz',
    'Something went wrong': 'Etwas ist schief gelaufen',
    'Incident has been reported and we will work to solve it':
        'Der Fehler wurde gemeldet, wir werden schnellstmöglich an der Behebung arbeiten',
    'Go back': 'Zurück',
    'Try again': 'Noch einmal versuchen',
    'diet-faq/title': 'Fragen',

    'account/activate/title': 'Account Aktivierung',
    'account/activate/error': 'Kontoaktivierung fehlgeschlagen.',

    'coupon/title': 'Aktivierung',
    'coupon/register-user/title':
        'Eröffne ein Konto und gib den Zugangscode ein',
    'coupon/access-code': 'Zugangscode',
    'coupon/login/title': 'Ich habe ein Konto',
    'coupon/activate/title': 'Gebe deinen Zugangscode für den Dienst ein',
    'coupon/activated-successfully': 'Coupon wurde aktiviert',
    'coupon/activate-card/title': 'Aktiviere deinen Zugangscode',
    'coupon/card-instruction': 'Um deine Zugangskarte zu nutzen:',
    'coupon/card-instruction/1': 'Fülle alle Felder des Dokuments aus',
    'coupon/card-instruction/2': 'Trage den Code deiner Karte ein',
    'coupon/card-instruction/3':
        'Wenn alle Angaben korrekt sind, klicke das Feld. ',
    'coupon/send': 'Aktiviere deinen Zugang',
    'agreements/terms-and-conditions/text':
        'Ich akzeptiere die AGBs des Dienstes',
    'agreements/processing-personal-data/text':
        'Ich stimme der Übertragung meiner persönlichen Daten zu',
    'agreements/processing-personal-data/text-more':
        'durch DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, als Administrator persönlicher Daten, mit dem Ziel der Ausführung der zugänglichen Dienstleistungen und mit marketingorientierten Zielen, darin enthalten das Erhalten von E-Mails auf dem angegeben E-Mail-Konto mit Handelsinformationen versandt durch DietLabs sp. z. o.o. sp. k. Im eigenen Namen oder auf Anraten der Businesspartner. Sie haben das Recht ihre Daten einzusehen, sie zu verbessern oder sie zu löschen.',
    'newsletter/unsubscribe/title': 'Schade dass du uns verlässt',
    'newsletter/unsubscribe/success':
        'Die E-Mail-Adresse wurde aus dem Newsletter herausgenommen.',
    'newsletter/unsubscribe/send': 'Trag mich ein',
    'read-more': 'Mehr',
    'cart/title': 'Einkaufskorb',
    'cart/payment-method': 'Zahlungsinformationen',
    'cart/free-delivery-info':
        'Füge einen Artikel für {{price}} um einen kostenlosen Versand zu erhalten.',
    'cart/delivery/heading': 'Versandadresse',
    'cart/delivery/name': 'Vor- und Nachname oder Firmenname',
    'cart/delivery/street': 'Straße und Hausnummer',
    'cart/delivery/postal-code': 'Postleitzahl',
    'cart/delivery/city': 'Stadt',
    'cart/delivery/phone': 'Telefonnummer',
    'cart/delivery/country': 'Land',
    'cart/summary': 'Zusammenfassung der Bestellung',
    'cart/summary/products': 'Alle Produkte',
    'cart/summary/delivery': 'Lieferung',
    'cart/summary/total': 'Zu zahlen',
    'cart/pay': 'Zahlen',
    'cart/accept-condition/text':
        'Ich stimme der Übertragung meiner persönlichen Daten zu',
    'cart/accept-condition/text-more':
        'durch DietLabs sp. z. o.o. sp. k. ul. J. Chełmońskiego 8/4 60-754 Poznań, als Administrator persönlicher Daten, mit dem Ziel der Ausführung der zugänglichen Dienstleistungen und mit marketingorientierten Zielen, darin enthalten das Erhalten von E-Mails auf dem angegeben E-Mail-Konto mit Handelsinformationen versandt durch DietLabs sp. z. o.o. sp. k. Im eigenen Namen oder auf Anraten der Businesspartner. Sie haben das Recht ihre Daten einzusehen, sie zu verbessern oder sie zu löschen.',
    'cart/accept-condition/validation/required':
        'Einverständnis Erklärung notwendig',
    'cart/upsell/you-save': 'Du sparst',
    'cart/upsell/add-product': 'Zum Einkaufskorb hinzufügen',
    'cart/upsell/added': 'Hinzugefügt',
    'cart/upsell/about': 'Über das Produkt',
    'cart/next': 'Weiter',
    'cart/back-to-cart': 'Zurück zum Einkaufskorb',
    'cart/confirm-and-order': 'Ich bestätige und bestelle',
    'cart/change': 'Ändern',
    'cart/order-summary': 'Zusammenfassung der Bestellung',
    'cart/address-of-delivery': 'Versandadresse',
    'cart/payment': 'Zahlung',
    'cart/payment/error-occured':
        'Wir entschuldigen aber wir konnten den Zahlungsvorgang nicht einleiten. Versuche es nochmal und kontaktiere uns falls das Problem sich wiederholt.',
    'cart/payment-cod/error-occured':
        'Wir entschuldigen, aber wir konnten deine Bestellung nicht abschließen. Versuche es nochmal und lass uns wissen wenn sich das Problem wiederholt.',
    'cart/order-is-pending': 'Deine Bestellung wurde aufgenommen.',
    'cart/add/invalid-product-id':
        'Das ausgewählte Produkt ist nicht im Verkauf erhältlich.',
    'cart/login-or': 'oder',
    'thank-you/title': 'Danke für deine Bestellung – was nun?',
    'thank-you/how-to-activate-the-service': 'Wie aktiviere ich den Dienst?',
    'thank-you/step1':
        'Lade die E-Mail in der du die Bestellübersicht und Bestellbestätigung findest.',
    'thank-you/step2a':
        'Wir gehen zur Vervollständigung deiner Bestellung über.',
    'thank-you/step2b':
        'Wir schließen den Bestellvorgang ab sobald der Zahlungseingang von deinem Konto erfolgt.',
    'thank-you/step2c':
        'Wir informieren dich per E-Mail sobald das Paket versandt werden kann.',
    'thank-you/step3':
        'In der nächsten Nachricht bekommst du einen Code und eine Anleitung darüber wie du deine Bestellung besser kontrollieren kannst. 91% der Versendungen werd innerhalb der nächsten zwei Arbeitstage bearbeitet.',
    'thank-you/activation-code-and-instruction':
        'Den Aktivierungscode mitsamt Anleitung zur Aktivierung bekommst du zugeschickt.',
    'thank-you/flyer-header': 'Diet by Ann - Zugangscode',
    'thank-you/flyer-content1':
        'Geh nicht mit der Einstellung zum Abnehmen, dass es mühsam, mit Entbehrungen und Qualen verbunden sein muss.',
    'thank-you/flyer-content2':
        'Betrachte das Ziel überflüssige Pfunde los zu werden als eine Herausforderung, der du positiv begegnest.',
    'thank-you/flyer-content3': 'Anna Lewandowska',
    'thank-you/flyer-footer':
        'Gehe auf die Seite {{site_url}}/Aktivierung, trage den untenstehenden Code ein und aktiviere den Dienst.',
    'thank-you/go-to-diet-plan': 'Zu den Diät-Plänen',
    'thank-you/go-to-diet-plan/content':
        'Deine Diät ist erstellt. Entdecke neue Gerichte, was gesund und nähere dich gleichzeitig mit jedem Tag deiner Traumfigur.',
    'thank-you/start-configuring-your-diet':
        'Beginne mit der Konfiguration deiner Diät',
    'thank-you/start-configuring-your-diet/content':
        'Bevor du deinen Weg zum Traumkörper beginnst, brauchen wir ein paar zusätzliche Informationen von dir.',
    'thank-you/start-configuring-your-diet/button': 'Konfiguriere deine Diät',
    'thank-you/warning':
        'ACHTUNG! Um die Funktionen des Dienstes vollständig nutzen zu können, musst du dein Konto aktivieren. Wir haben dir den Aktivierungslink an die Adresse {{email}} zugeschickt. Falls du die E-Mail nicht erhalten hast, überprüfe deinen Spam Ordner',
    'thank-you/start-training': 'Beginne das Training',
    'thank-you/start-training/content':
        'Die Trainingseinheiten sind ausschließlich in der App Diet & Training by Ann für Mobilfunkgeräte des Betriebssystems iOS und Android verfügbar. Klick auf den App Icon, lade die App herunter und starte dein Training mit Anna Lewandowska.',
    'loading/message': 'Lädt...',
    'et-al/abbrev': 'usw.',
    'et-al/full': 'und ähnliche',
    'payment/pending/title': 'Folgendes Paket hast du erworben: {{produts}}',
    'payment/pending/description':
        'Du erhältst Zugang zu deinem Dienst sobald wir deine Bezahlung erhalten haben. Die Wartezeit hängt von der von dir gewählten Zahlmethode ab.',
    'payment/pending/make-payment-description':
        'Falls du noch nicht gezahlt hast, kannst du dies jetzt tun.',
    'payment/pending/make-payment': 'Jetzt zahlen',
    'message/showing-cached-data': 'Deine Angaben könnten nicht aktuell sein.',
    'message/showing-cached-data/network-error':
        'Wir konnten deine Angaben nicht verarbeiten - deine Angaben könnten nicht aktuell sein.',
    'message/showing-cached-data/network-error-offline':
        'Fehlende Internetverbindung – die angezeigten Angaben könnten nicht aktuell sein.',
    'workouts/title': 'Übungen und Trainingspläne',
    'units/mililiter/full': 'Mililiter',
    'units/mililiter/full_plural': 'Mililiter',
    'units/mililiter/abbr': 'ml',
    'units/ounce/full': 'Unzen',
    'units/ounce/full_plural': 'Unzen',
    'units/ounce/abbr': 'oz.',

    'route-title/hydration-day': 'Dein Flüssigkeitshaushalt',
    'menu/hydration': 'Flüssigkeitszufuhr',
    'menu/your-hydration': 'Dein Flüssigkeitshaushalt',

    'hydration/add-custom': 'Eigene Wassermenge hinzufügen',
    'hydration/goal-label': 'Tägliches Ziel:',

    'hydration/goal-header/reached':
        'Glückwunsch! Du hast dein Tagesziel der Flüssigkeitszufuhr erreicht.',
    'hydration/goal-reached-label': 'Glückwunsch!',
    'hydration/goal-reached1': 'Du hast dein Ziel erreicht.',
    'hydration/goal-reached2':
        'Das ist wichtig denn eine optimale Flüssigkeitszufuhr verbessert die Arbeit und Funktion des gesamten Körpers.',

    'hydration/goal-changer-label': 'Tägliches Ziel für die Flüssigkeitszufuhr',
    'hydration/goal-left/past/pre-value': 'Heute sollest du noch',
    'hydration/goal-left/past/post-value': 'Wasser trinken',
    'hydration/goal-left/present/pre-value': 'Heute solltest du noch',
    'hydration/goal-left/present/post-value': 'Wasser trinken',
    'hydration/goal-left/future/pre-value': '{{date}} solltest du trinken',
    'hydration/goal-left/future/post-value': 'Wasser',
    'hydration/goal-reached':
        'Glückwunsch! Du hast dein Tagesziel der Flüssigkeitszufuhr erreicht!',
    'hydration/progress/label': 'Getrunken',
    'hydration/remove-last-portion': 'Letzte Wassermenge löschen',
    'route-title/hydration-add': 'Etwas Wasser hinzufügen',
    'hydration/add-portion': 'Eine Portion hinzufügen',
    'hydration/add/heading': 'Wie viel Wasser hast du getrunken?',
    'hydration/add/submit': 'Zufügen',
    'hydration/add/cancel': 'Abbrechen',
    'hydration/add/custom': 'Wähle selbst',
    'hydration/full-glass': 'Ein volles Glas',
    'hydration/full-bottle': 'Eine volle Flasche.',
    'hydration/add-global-action':
        'Über den heutigen Tag getrunkene Wassermenge eintragen.',
    'route-title/hydration-history': 'Trinkverlauf',
    'menu/hydration-history': 'Historie',
    'hydration/go-to-history': 'Historie',
    'hydration/back-to-hydration': 'Zurück',
    'hydration/recommended-value': '{{value}} (Empfohlen)',
    'hydration/glass-value': '{{value}} (Gläser)',
    'hydration/glass': 'Gläser',
    'hydration/half-bottle-value': '{{value}} (Halbe Flaschen)',
    'hydration/bottle-value': '{{value}} (Flaschen)',
    'hydration/bottle': 'Flaschen',
    'mindfulness/title': 'Balance',

    'calendar/january': 'Januar',
    'calendar/february': 'Februar',
    'calendar/march': 'März',
    'calendar/april': 'April',
    'calendar/may': 'Mai',
    'calendar/june': 'Juni',
    'calendar/july': 'Juli',
    'calendar/august': 'August',
    'calendar/september': 'September',
    'calendar/october': 'Oktober',
    'calendar/november': 'November',
    'calendar/december': 'Dezember',

    'calendar/monday': 'M',
    'calendar/tuesday': 'D',
    'calendar/wednesday': 'M',
    'calendar/thursday': 'D',
    'calendar/friday': 'F',
    'calendar/saturday': 'S',
    'calendar/sunday': 'S',

    'calendar/reset': 'Zurücksetzen',
    'calendar/ok': 'OK',

    'fav-meals/added': 'Zu Favoriten hinzugefügt.',
    'fav-meals/removed': 'Aus Favoritenliste entfernen.',
    'fav-meals/back': 'Undo',
    'fav-meals/added-to-dayplan': 'Mahlzeit wurde dem Menü hinzugefügt.',
    'fav-meals/added-to-dayplan-today': 'Mahlzeit wurde dem Menü hinzugefügt.',
    'fav-meals/added-to-dayplan-tomorrow':
        'Mahlzeit wurde dem Menü hinzugefügt.',
    'fav-meals/insert-into-dayplan': 'Zum Ernährungsplan hinzufügen',
    'fav-meals/insert-today': 'Heute',
    'fav-meals/insert-tomorrow': 'Morgen',
    'fav-meals/insert-date': 'Wähle ein Datum',

    'terms-of-service/title': 'AGB',
    'terms-of-service/android': 'Kauf im Google Play',
    'terms-of-service/huawei': 'Kauf im Huawei AppGallery',
    'terms-of-service/ios': 'Kauf im App Store',

    'intetmittent-fasting/end-of-post':
        'Das Fastenziel wurde erreicht. Beende das aktuelle Fasten.',
    'intetmittent-fasting/next-eat-window-today': 'Geplantes Fastenende: heute',
    'intetmittent-fasting/next-eat-window-tomorrow':
        'Geplantes Fastenende: morgen',
    'intetmittent-fasting/eat-window-to-today': 'Essenfenster bis heute',
    'intetmittent-fasting/eat-window-to-tomorrow': 'Essenfenster bis morgen',

    'developer-options/api-gateway': 'API Gateway',
    'developer-options/set-api-gateway': 'Ustaw API Gateway',
    'developer-options/api-gateway-changed': 'API Gateway zostało zmienione',
    'developer-options/clear-data': 'Wyczyść dane',
    'developer-options/clear-apollo-cache': 'Wyczyść Apollo Cache',
    'developer-options/apollo-cache-cleared':
        'Apollo Cache został wyczyszczony',
    'developer-options/clear-local-storage': 'Wyczyść Local Storage',
    'developer-options/local-storage-cleared':
        'Local Storage został wyczyszczony',
    'developer-options/remove-service-worker': 'Usuń Service Worker',
    'developer-options/service-worker-removed':
        'Service Worker został wyrejestrowany',
    'developer-options/create-new-user': 'Utwórz nowego użytkownika',
    'developer-options/new-user-created': 'Nowy użytkownik został utworzony',
    'developer-options/add-access': 'Dodaj dostęp',
};
