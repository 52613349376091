import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled2';
import withStep from './StepContext/withStep';
import { withLocale } from '../../../TranslatorContext';

class StepActivityLevel extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        activityType: PropTypes.string.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.changeNextBtnState(!this.props.activityType);
    }

    componentDidUpdate() {
        this.props.changeNextBtnState(!this.props.activityType);
    }

    render() {
        const { activityType, handleInputChange } = this.props;

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <h3 className="text-center">
                        {this.props.t('diet-settings/activity-type/question')}
                    </h3>

                    <FormGroup tag="fieldset">
                        <InputRadio
                            label={[
                                <div key="classic">
                                    <strong>
                                        {this.props.t(
                                            'diet-settings/activity-type/classic'
                                        )}
                                    </strong>
                                    <p>
                                        {this.props.t(
                                            'diet-settings/activity-type/classic/description'
                                        )}
                                    </p>
                                </div>,
                            ]}
                            name="activityType"
                            value="simple"
                            handleChange={handleInputChange}
                            checked={activityType === 'simple'}
                            data-test="activity-type-simple-input"
                            rounded
                            className="panel"
                        />
                        <InputRadio
                            label={[
                                <div key="trainings">
                                    <strong>
                                        {this.props.t(
                                            'diet-settings/activity-type/trainings'
                                        )}
                                    </strong>
                                    <p>
                                        {this.props.t(
                                            'diet-settings/activity-type/trainings/description'
                                        )}
                                    </p>
                                </div>,
                            ]}
                            name="activityType"
                            value="trainings"
                            handleChange={handleInputChange}
                            checked={activityType === 'trainings'}
                            data-test="activity-type-trainings-input"
                            rounded
                            className="panel"
                        />
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default withStep(withLocale(StepActivityLevel));
