import React, { Component } from 'react';
import Fraction from 'fraction.js';
import PropTypes from 'prop-types';

class MeasurementUnits extends Component {
    render() {
        return (
            <span className="float-right text-right">
                {this.props.measurements.map((measure, k) => {
                    let res;
                    if (
                        measure.unit === 'g' ||
                        measure.unit === 'ml' ||
                        measure.unit === 'oz' ||
                        measure.unit === 'fl oz'
                    ) {
                        res = Math.round(measure.amount * 100) / 100;
                    } else {
                        let number = measure.amount;

                        const intNumber = Math.floor(number);
                        let decimalNumber = number % 1;
                        decimalNumber = Math.round(decimalNumber * 100) / 100;

                        if (decimalNumber === 0.33) {
                            decimalNumber = '.(3)';
                        } else if (decimalNumber === 0.66) {
                            decimalNumber = '.(6)';
                        } else if (decimalNumber === 0.16) {
                            decimalNumber = '.1(6)';
                        } else if (decimalNumber === 0.83) {
                            decimalNumber = '.8(3)';
                        }

                        number = intNumber + decimalNumber;

                        const x = new Fraction(number);
                        const availableDenominators = [2, 3, 4, 5, 6, 8];

                        // natural fraction
                        if (availableDenominators.indexOf(x.d) > -1) {
                            res = x.toFraction(true);
                            res = res.split('/');

                            if (res.length > 1) {
                                res = res.map((item, key) => {
                                    if (key === 0) {
                                        const itemParts = item.split(' ');
                                        if (itemParts.length === 1) {
                                            return (
                                                <span key={key}>
                                                    <sup>{item}</sup>/
                                                </span>
                                            );
                                        }

                                        return (
                                            <span key={key}>
                                                {itemParts[0]}
                                                <sup>{itemParts[1]}</sup>/
                                            </span>
                                        );
                                    }

                                    return <sub key={key}>{item}</sub>;
                                });
                            }
                        }
                        // decimal fraction
                        else {
                            res = Math.round(measure.amount * 100) / 100;
                        }
                    }

                    if (res !== 0) {
                        return (
                            <span key={k}>
                                {k + 1 === 2 ? ' ≈ ' : ''}
                                {measure.unit === 'piece' ? (
                                    <span>{res}</span>
                                ) : (
                                    <span className="text-nowrap">
                                        {res} {measure.text.replace('%s', '')}
                                    </span>
                                )}
                            </span>
                        );
                    }
                    return '';
                })}
            </span>
        );
    }
}

MeasurementUnits.propTypes = {
    measurements: PropTypes.arrayOf(
        PropTypes.shape({
            amount: PropTypes.number.isRequired,
            text: PropTypes.string.isRequired,
            unit: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default MeasurementUnits;
